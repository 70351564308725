import React from 'react'
import ProfileLigth from '../../components/ProfileLight'
import CardVitalSign from '../../components/CardVitalSign'
import ImgProfile from '../../../../assets/images/profile.png'
import { VITALS_CONFIG } from './constants'
import Moment from 'moment'
import { DiagramECG } from '../../components/DiagramECG'

const vitalsign = [
  {
    alias: 'heart',
    value: '60/min'
  },
  {
    alias: 'pressure',
    value: '90/60 mmHg'
  },
  {
    alias: 'saturation',
    value: '95%'
  },
  {
    alias: 'breath',
    value: '20/min'
  },
  {
    alias: 'temperature',
    value: '37°'
  },
  {
    alias: 'position',
    value: 'Caminando'
  }
]

interface Props {
  patient: any
}

const Profile: React.FC<Props> = ({ patient }) => {
  const patientVitals = JSON.parse(sessionStorage.getItem('patient') as string)

  const getAgeActually = (birthDate: string) => {
    const current = Moment(birthDate).format('YYYY-MM-DD')
    const years = Moment().diff(current, 'years',false);
    return years
  }

  const getValueVital = (alias: string, patientVitals: any): string | undefined => {
    if (alias === 'heart') return patientVitals?.hr
    if (alias === 'pressure') return patientVitals?.pressure
    if (alias === 'saturation') return patientVitals?.saturation
    if (alias === 'breath') return patientVitals?.breath
    if (alias === 'temperature') return patientVitals?.temperature
    if (alias === 'position') return patientVitals?.position
  }

  return (
    <>
      <ProfileLigth
        imgSrc={patient?.gender === 'F' ? ImgProfile : null}
        name={patient?.name}
        age={getAgeActually(patient?.birthday)}
        profile={patient}
      />
      <div>
        <div className="flex-col sm:flex-row	mt-4 mb-4 sm:grid sm:grid-cols-2 2xl:grid-cols-3 gap-2">
          {
            vitalsign?.map((sign, i) => {
              const {value, alias} = sign
              return(
                <span key={i} className="p-1">
                  <CardVitalSign
                    vitalValue={getValueVital(alias, patientVitals)}
                    vitalName={VITALS_CONFIG[alias].text}
                    imgSrc={VITALS_CONFIG[alias].icon()}
                  />
                </span>
            )})
          }
        </div>
        <DiagramECG />
      </div>
    </>
  )
}

export default Profile

import { AttentionsModel, PatientsModel } from "features/shared/types/userType";
import { UseMutateAsyncFunction } from "react-query";
import { IFiltersData } from "./Table/TableUpperActions";
import { useEffect } from "react";
import { debounceTime, Observable } from "rxjs";


const defaultElementsOnFailure: any = {
  currentPage: 1,
  data: [],
  total: 1,
  totalPages: 1,
};

export enum HEADER_ELEMENT_KEYS {
  FULL_NAME = "full_name",
  REGION = "province",
  PHONE = "phone",
  DOCUMENT_NUMBER = "document_number",
  ADDRESS = "address",
  NHC = "clinic_history_number",
  ATTENTION_DATE = "attention_date",
  ADMISSION_DATE = "admission_date",
  RELEASE_DATE = "RELEASE_DATE",
  DIAGNOSIS = "diagnosis",
  HOSPITALIZATION_DAYS = "hospitalization_days",
  ACTIONS = "actions",
  LOCATION = "location",
  ATTENTION_NUMBER = 'attention_number',
  ATTENTION_STATUS = 'attention_status',
  EVALUATING_DOCTOR_FULL_NAME = 'evaluating_doctor_FULL_NAME'
}

export const HEADER_SORT_ORDER_HISTORY = [
  HEADER_ELEMENT_KEYS.FULL_NAME,
  HEADER_ELEMENT_KEYS.REGION,
  HEADER_ELEMENT_KEYS.DOCUMENT_NUMBER,
  HEADER_ELEMENT_KEYS.NHC,
  HEADER_ELEMENT_KEYS.ATTENTION_DATE,
  HEADER_ELEMENT_KEYS.RELEASE_DATE,
  HEADER_ELEMENT_KEYS.DIAGNOSIS,
  HEADER_ELEMENT_KEYS.ACTIONS,
]

export const HEADER_SORT_ORDER_PATIENTS = [
  HEADER_ELEMENT_KEYS.FULL_NAME,
  HEADER_ELEMENT_KEYS.REGION,
  HEADER_ELEMENT_KEYS.DOCUMENT_NUMBER,
  HEADER_ELEMENT_KEYS.NHC,
  HEADER_ELEMENT_KEYS.ATTENTION_DATE,
  HEADER_ELEMENT_KEYS.DIAGNOSIS,
  HEADER_ELEMENT_KEYS.HOSPITALIZATION_DAYS,
  HEADER_ELEMENT_KEYS.ACTIONS,
]

export const HEADER_SORT_ORDER_ATTENTIONS = [
  HEADER_ELEMENT_KEYS.FULL_NAME,
  HEADER_ELEMENT_KEYS.REGION,
  HEADER_ELEMENT_KEYS.DOCUMENT_NUMBER,
  HEADER_ELEMENT_KEYS.ATTENTION_NUMBER,
  HEADER_ELEMENT_KEYS.ATTENTION_DATE,
  HEADER_ELEMENT_KEYS.DIAGNOSIS,
  HEADER_ELEMENT_KEYS.ATTENTION_STATUS,
  HEADER_ELEMENT_KEYS.ACTIONS,
]

export const HEADER_SORT_ORDER_ATTENTIONS_SPECIFIC_PATIENT = [
  HEADER_ELEMENT_KEYS.EVALUATING_DOCTOR_FULL_NAME,
  HEADER_ELEMENT_KEYS.NHC,
  HEADER_ELEMENT_KEYS.ATTENTION_NUMBER,
  HEADER_ELEMENT_KEYS.ATTENTION_DATE,
  HEADER_ELEMENT_KEYS.RELEASE_DATE,
  HEADER_ELEMENT_KEYS.DIAGNOSIS,
  HEADER_ELEMENT_KEYS.ATTENTION_STATUS,
  HEADER_ELEMENT_KEYS.ACTIONS
]

export const HEADER_SORT_ORDER_ATTENTIONS_PATIENTS = [
  HEADER_ELEMENT_KEYS.FULL_NAME,
  HEADER_ELEMENT_KEYS.REGION,
  HEADER_ELEMENT_KEYS.DOCUMENT_NUMBER,
  HEADER_ELEMENT_KEYS.ATTENTION_NUMBER,
  HEADER_ELEMENT_KEYS.ATTENTION_DATE,
  HEADER_ELEMENT_KEYS.DIAGNOSIS,
  HEADER_ELEMENT_KEYS.ATTENTION_STATUS,
  HEADER_ELEMENT_KEYS.ACTIONS,
]

export const HEADER_SORT_ORDER = [
  HEADER_ELEMENT_KEYS.FULL_NAME,
  HEADER_ELEMENT_KEYS.REGION,
  HEADER_ELEMENT_KEYS.DOCUMENT_NUMBER,
  HEADER_ELEMENT_KEYS.NHC,
  HEADER_ELEMENT_KEYS.ATTENTION_DATE,
  HEADER_ELEMENT_KEYS.RELEASE_DATE,
  HEADER_ELEMENT_KEYS.DIAGNOSIS,
  HEADER_ELEMENT_KEYS.ACTIONS,
]

export const TABLE_HEADER_LABELS = {
  [HEADER_ELEMENT_KEYS.FULL_NAME]: "Nombre y apellidos",
  [HEADER_ELEMENT_KEYS.REGION]: "Región",
  [HEADER_ELEMENT_KEYS.PHONE]: "N° de contacto",
  [HEADER_ELEMENT_KEYS.DOCUMENT_NUMBER]: "N°. documento",
  [HEADER_ELEMENT_KEYS.ADDRESS]: "Dirección",
  [HEADER_ELEMENT_KEYS.LOCATION]: "",
  [HEADER_ELEMENT_KEYS.ADMISSION_DATE]: "Fecha de registro",
  [HEADER_ELEMENT_KEYS.NHC]: "N° historia clínica",
  [HEADER_ELEMENT_KEYS.ATTENTION_DATE]: "Fecha de atención",
  [HEADER_ELEMENT_KEYS.RELEASE_DATE]: "Fecha de alta",
  [HEADER_ELEMENT_KEYS.DIAGNOSIS]: "Diagnóstico",
  [HEADER_ELEMENT_KEYS.ACTIONS]: "Acciones",
  [HEADER_ELEMENT_KEYS.HOSPITALIZATION_DAYS]: "Días hosp.",
  [HEADER_ELEMENT_KEYS.ATTENTION_STATUS]: "Estado",
  [HEADER_ELEMENT_KEYS.ATTENTION_NUMBER]: "N° de atención",
  [HEADER_ELEMENT_KEYS.EVALUATING_DOCTOR_FULL_NAME]: "Médico evaluador"
}

export interface IDataFetchingProps<T, E> {
  currentPage: number,
  userCurrent: any,
  searchMutation: UseMutateAsyncFunction<{
    data: any
  }, unknown, T, unknown>,
  searchFunction: ({ currentPage, userCurrent, searchMutation, setResults, filtersData }: IGetPatientAttentions<T>) => Promise<void>
  setResults: React.Dispatch<React.SetStateAction<E>>,
  filtersData: IFiltersData | undefined,
  initial: boolean,
  setIsInitial: React.Dispatch<React.SetStateAction<boolean>>
  inputChange$: Observable<string>
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

export interface IGetPatientAttentions<T> {
  currentPage: number,
  searchMutation: UseMutateAsyncFunction<{
    data: T,
  }, unknown, any, unknown>,
  setResults: React.Dispatch<React.SetStateAction<T>>
  userCurrent: {
    [index: string]: any;
  } | null,
  filtersData?: IFiltersData
}

export const useHandleDataLoading = ({
  currentPage,
  userCurrent,
  searchMutation,
  setResults,
  filtersData,
  initial,
  setIsInitial,
  inputChange$,
  searchFunction,
  setCurrentPage
}: IDataFetchingProps<any, any>) => {
  useEffect(() => {
    if (initial) {
      return;
    }
    searchFunction({
      currentPage,
      userCurrent,
      searchMutation,
      setResults,
      filtersData
    }).catch((error) => {
      console.log({ error });
    });

  }, [currentPage]);

  //We immediately call the function to get the data if the filters change but not the text
  useEffect(() => {
    if (initial) {
      return;
    }
    if (currentPage >= 2) {
      setCurrentPage(1);
    } else {
      searchFunction({
        currentPage,
        userCurrent,
        searchMutation,
        setResults,
        filtersData
      }).catch((error) => {
        console.log({ error });
      });
    }

  }, [JSON.stringify({ ...filtersData, text: undefined })]);


  //We debounce the search if the text changes
  useEffect(() => {
    if (initial) {
      return;
    }

    const searchSubscription = inputChange$
      .pipe(debounceTime(1000))
      .subscribe(() => {
        setCurrentPage(1);  //We search from page 0
        searchFunction({
          currentPage: 1, //We search from page 0
          userCurrent,
          searchMutation,
          setResults,
          filtersData
        }).catch((error) => {
          console.log({ error });
        });
      });

    return () => searchSubscription.unsubscribe();
  }, [filtersData?.text]);

  //We get the data when the component mounts
  useEffect(() => {
    if (!initial) {
      return;
    }
    searchFunction({
      currentPage,
      userCurrent,
      searchMutation,
      setResults,
      filtersData
    }).finally(() => {
      setIsInitial(false);
    });
  }, []);
}

export const onGetPatientAttentions = async ({
  userCurrent,
  searchMutation,
  setResults,
  currentPage,
  filtersData
}: IGetPatientAttentions<AttentionsModel>) => {
  if (userCurrent?.ipress?.countryId) {
    let response: {
      data: AttentionsModel;
    } = {
      data: defaultElementsOnFailure
    };
    try {
      response = await searchMutation({
        searchText: filtersData?.text,
        region: filtersData?.region,
        attentionDateStart: filtersData?.attentionDatesRange?.startDateRange,
        attentionDateEnd: filtersData?.attentionDatesRange?.endDateRange,
        releaseDateStart: filtersData?.releaseDatesRange?.startDateRange,
        releaseDateEnd: filtersData?.releaseDatesRange?.endDateRange,
        page: currentPage,
        serviceStatusIn: filtersData?.serviceStatusIn,
        patientId: filtersData?.patientId
      });
    } catch (e) {
      console.error({ e });
    }
    setResults(response.data)
  }
}

export const onGetListPatients = async ({
  currentPage,
  userCurrent,
  searchMutation,
  setResults,
  filtersData
}: IGetPatientAttentions<PatientsModel>) => {
  if (userCurrent?.ipress?.countryId) {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append('country', userCurrent?.ipress?.countryId);
    urlSearchParams.append('page', (currentPage || "1")?.toString());
    if (filtersData?.text) urlSearchParams.append('searchText', filtersData?.text);
    // urlSearchParams.append('searchAllCountry', Boolean(filtersData?.isCheckedSearchCountry).valueOf().toString());
    if (filtersData?.region) {
      urlSearchParams.append('region', filtersData?.region);
      urlSearchParams.append('searchAllCountry', "false");
    } else{
      urlSearchParams.append('searchAllCountry', "true");
    }
    //We're looking for active patients only. We can specify different
    //service statuses here to vary the results.
    if (Array.isArray(filtersData?.serviceStatusIn)) {
      urlSearchParams.append('serviceStatusIn', JSON.stringify(filtersData?.serviceStatusIn));
    } else {
      urlSearchParams.append('serviceStatusIn', JSON.stringify([1, 2]));
    }

    if (filtersData?.attentionDatesRange?.startDateRange) urlSearchParams.append('attentionDateStart', filtersData?.attentionDatesRange?.startDateRange.toISOString());
    if (filtersData?.attentionDatesRange?.endDateRange) urlSearchParams.append('attentionDateEnd', filtersData?.attentionDatesRange?.endDateRange.toISOString());
    if (filtersData?.releaseDatesRange?.startDateRange) urlSearchParams.append('releaseDateStart', filtersData?.releaseDatesRange?.startDateRange.toISOString());
    if (filtersData?.releaseDatesRange?.endDateRange) urlSearchParams.append('releaseDateEnd', filtersData?.releaseDatesRange?.endDateRange.toISOString());
    if(filtersData?.specialistId) urlSearchParams.append('specialistId', filtersData?.specialistId);

    const query = `?${urlSearchParams.toString()}`;
    let response: {
      data: PatientsModel;
    } = {
      data: defaultElementsOnFailure
    };
    try {
      response = await searchMutation(query);
    } catch (e) {
      console.error({ e });
    }
    setResults(response.data)
  }
}
import Button from "components/Button"
import ButtonToggle from "components/ButtonToggle"
import IconFile from "assets/icons/icon_file.svg";
import IconFilePlus from "assets/icons/icon_file_plus.svg";
import IconCall from "assets/icons/icon_call.svg";
import IconMeet from "assets/icons/icon_meet.svg";
import { useTranslation } from "react-i18next";
import { MeetingVideoCall } from "components/MeetingVideoCall";
import { Dispatch, SetStateAction, useState } from "react";
import { useCreateGeneralNotification, useCreateNotificationCallDoctor, useCreateNotificationEmergency } from "features/ClinicHistory/hooks/useNotification";
import { WORKPLAN_TABS } from "./types";
import { ROLE } from "global/constants/roles";
import { EventType } from "global/constants/eventType";
import { useCallContext } from "features/CallCenter/hooks/useCurrentCallContext";

export const PatientWorkPlanHeader = ({
    patientId,
    planToday,
    userData,
    setSelectedTab,
    selectedTab
}: {
    patientId: number,
    planToday: any,
    userData: any,
    setSelectedTab: Dispatch<SetStateAction<WORKPLAN_TABS>>
    selectedTab: WORKPLAN_TABS
}) => {
    const { t } = useTranslation()
    const { mutateAsync: createNotificationEmergency } = useCreateNotificationEmergency()
    const { mutateAsync: createCustomNotification } =
        useCreateGeneralNotification()
    const {
        setCurrentMeeting
    } = useCallContext()

    async function handleNotifyDoctorOfPatientCall() {
        try {
            await createCustomNotification({
                json: {
                    patientId,
                },
                fromRole: ROLE.PATIENT,
                toRole: ROLE.HEALTH_PROFESSIONAL,
                type: EventType.NOTIFICATION_TYPE_PATIENT_CALL
            })
        } catch (e) {
            console.log({
                e
            });
        }
    }

    const handleClickCallDoctor = () => {
        setTimeout(() => {
            handleNotifyDoctorOfPatientCall()
            setCurrentMeeting({
                planId: planToday?.currentService?.id,
                userId: patientId,
                getIsClose: () => {
                    setCurrentMeeting(undefined)
                },
                initCall: true
            })
        }, 500)
    }

    const handleClickCallEmergency = () => {
        setTimeout(() => {
            createNotificationEmergency({
                patientId
            })
            setCurrentMeeting({
                planId: planToday?.currentService?.id,
                userId: patientId,
                getIsClose: () => {
                    setCurrentMeeting(undefined)
                },
                initCall: true
            })
        }, 500)
    }

    return (
        <div>
            <div className="flex gap-2 grid sm:grid-cols-1 lg:grid-cols-2">
                <div className='flex flex-1 gap-2'>
                    <ButtonToggle icon={IconFile} isActive={selectedTab === WORKPLAN_TABS.WORK_PLAN} onClick={() => {
                        setSelectedTab(WORKPLAN_TABS.WORK_PLAN);
                    }} >
                        Plan de Trabajo
                    </ButtonToggle>
                    <ButtonToggle icon={IconFilePlus} isActive={selectedTab === WORKPLAN_TABS.DAILY_EVOLUTION} onClick={() => {
                        setSelectedTab(WORKPLAN_TABS.DAILY_EVOLUTION);
                    }} >
                        Anotaciones del Paciente
                    </ButtonToggle>
                </div>
                <div className='sm:pb-12 lg:pb-0'>
                    <div className="sm:grid sm:grid-cols-1 lg:flex lg:justify-end sm:gap-4 lg:gap-2">
                        <div className="sm:pt-12 lg:pt-0">
                            <Button
                                onClick={handleClickCallEmergency}
                                type="button"
                                variant='emergency'
                                size="medium"
                                className='flex flex-row items-center bg-red-600 rounded-lg border-none w-full max-w-fit'>
                                <img src={IconCall} alt={IconCall} className='mr-[8px] sm:w-[24px] sm:w-[24px] sm:h-[24px]' />
                                {t('emergency')}
                            </Button>
                        </div>
                        <div className="sm:pt-2 lg:pt-0">
                            <Button
                                onClick={handleClickCallDoctor}
                                type="submit"
                                variant='primary'
                                size="medium"
                                className='flex flex-row items-center bg-cyan-400 rounded-lg border-none w-full whitespace-nowrap pr-4 max-w-fit' >
                                <img src={IconMeet} alt={IconCall} className='mr-[8px] sm:w-[25px] sm:h-[25px]' />
                                {t('call_the_doctor')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React from "react"
import IconMeetWhite from 'assets/icons/icon_video_line.svg';
import ButtonToggle from 'components/ButtonToggle';
import { useCallContext } from "features/CallCenter/hooks/useCurrentCallContext";
import useUser from "features/shared/hooks/useUser";
import { useCreateGeneralNotification } from "features/ClinicHistory/hooks/useNotification";
import { ROLE } from "global/constants/roles";
import { EventType } from "global/constants/eventType";

interface Props {
  imgSrc: any
  name: string
  age: number,
  profile: any
}

const ProfileLight: React.FC<Props> = (props) => {
  const { userCurrent } = useUser()

  const {
    setCurrentMeeting,
  } = useCallContext();
  const { mutateAsync: createCustomNotification } =
    useCreateGeneralNotification()

  async function callPatient() {
    try {
      setCurrentMeeting({
        planId: props?.profile?.service?.id,
        userId: userCurrent?.id,
        initCall: true,
        getIsClose: () => {
          setCurrentMeeting(undefined)
        }
      })
      await createCustomNotification({
        json: {
          patientId: `${props.profile?.userId}`,
        },
        fromRole: ROLE.SERVICE_ADMIN,
        toRole: ROLE.PATIENT,
        type: EventType.NOTIFICATION_TYPE_ADM_SERVICE_CALL
      })
    } catch (e) {
      console.log({
        e
      });
    }
  }


  return (
    <div className="flex items-center pt-6">
      <div className=" rounded-full w-20 h-20 overflow-hidden">
        {props.imgSrc && <img src={props.imgSrc} alt="imagen perfil" />}
      </div>
      <div className="ml-4">
        <p className="font-bold mb-4">{props.name}, {props.age} años</p>
        <ButtonToggle
          icon={IconMeetWhite}
          onClick={callPatient}
          isActive={true}
          className="h-[40px] rounded-[8px] bg-primary-main w-[221px] justify-center">
          <span className='text-white'>Llamar</span>
        </ButtonToggle>
      </div>
    </div>
  )
}

export default ProfileLight
import fetcher from 'features/shared/utils/fetcher'

export type AnnotationModel = {
  image: any
  id: number
  userId: number
  message: string
  name: string
  lastname: string
  speciality: string
  createdAt: string
  messageType: string
  sender: string
}

export type AnnotationPostModel = {
  planId: number | undefined
  userId: number
  message: string
}

export async function getAnnotations(serviceId: number | undefined) {
  return await fetcher.get<{data: AnnotationModel[]}>(`/plans/${serviceId}/annotations`)
}

export async function createAnnotation(newAnnotation: AnnotationPostModel) {
    return await fetcher.post('/plan/annotations', newAnnotation)
}
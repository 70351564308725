import React, { FC, useEffect, useState } from 'react'
import { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import "./index.css"
import es from 'date-fns/locale/es'
import ArrowRightIcon from 'assets/icons/icon_chevron_right.svg';

import { useTranslation } from 'react-i18next'
import Input from '../input'
import getDiagnosticos from 'features/shared/services/getDiagnosticos'
import Moment from "moment";

import 'react-virtualized/styles.css'
import 'react-virtualized-select/styles.css'
import { Controller, useForm } from 'react-hook-form'
import useUser from 'features/shared/hooks/useUser'
import { TEMP_COUNTRY_DEFAULT } from 'global/constants'
import ButtonToggle from 'components/ButtonToggle';
import IconUser from 'assets/icons/icon_user.svg';
import InputDatePicker from 'components/InputDatePicker';
import InputSelect from 'components/InputSelect';
import Button from 'components/Button';
import IconMap from 'assets/icons/icon_map.svg';
import ModalMap from '../MapLocation';
import { AddresSelectedModel } from '../MapLocation/MapModel'
import InputSelectSearch from 'components/InputSelectSearch'
import { PatientModel } from 'features/Patient/models/Patient.model'
import HomeConditionForm from 'components/HomeConditionForm'
import { useGetLocations, LocationModel } from 'features/shared/hooks/useGetLocations'

import Chip from 'components/Chip';

registerLocale('es', es);

interface Props {
  allDatapatient: PatientModel;
  onSubmit: (value: any) => any;
  onReloadPatient: () => any;
  setStep: (value: any) => any;
  pacienteId: any;
  medicoCampo: any;
  selectOptions: any;
}

const peruOptionsReasonForAdmission = [
  {
    label: 'Manejo médico',
    value: 'manejo_medico',
  },
  {
    label: 'Manejo de enfermería',
    value: 'manejo_de_enfermeria',
  }
];


const Icon: FC<{ iconName: string }> = ({ iconName }) => {
  return (
    <img src={iconName} alt={iconName} className="sm:w-[16px] sm:h-[16px]" />
  )
}

const FormService: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const [sectionstep, setIsectionstep] = React.useState(0);
  const [admissionDate, setAdmissionDate] = React.useState(new Date());
  const [serviceDate, setServiceDate] = React.useState(new Date());
  const [plannedReleaseDate, setPlannedReleaseDate] = React.useState(new Date());
  const [admissionDiagnosis, setdiAdmissionDiagnosis] = React.useState({ value: '', label: '' });
  const { register, watch, setValue, handleSubmit, control } = useForm();
  const { userCurrent } = useUser();
  const [showHomeForm, setShowHomeForm] = React.useState<boolean>(false);
  const [serviceData, setServiceData] = React.useState<any>(0);
  const [listProvinces, setListProvinces] = useState<LocationModel[]>([]);
  const [listDistricts, setListDistricts] = useState<LocationModel[]>([]);
  const getListLocations = useGetLocations();

  let TEMP_COUNTRY = userCurrent?.ipress?.countryId ? userCurrent?.ipress?.countryId : TEMP_COUNTRY_DEFAULT;
  const [addressSelected, setAddressSelected] = useState<AddresSelectedModel>();
  const [showModal, setShowModal] = useState(false);

  const patientData = props.allDatapatient;
  let sourceAdmission = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "source_admission");
  let placeAttention = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "place_attention");
  let serviceProvided = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "service_provided");
  let benefits = props.selectOptions?.filter((documentType: { type: string }) => documentType.type === "benefits");

  const [isSelectLoading, setIsSelectLoading] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<{ value: string, label: string }[]>([]);

  const handleAdmissionDate = (date: any) => {
    setAdmissionDate(date)
  }
  const handleServiceDate = (date: any) => {
    setServiceDate(date)
  }
  const handleDischargeDate = (date: any) => {
    setPlannedReleaseDate(date)
  }

  const handleSelectChangeInfo = (e: any) => setdiAdmissionDiagnosis(e);

  const handleInputSelectChange = (value: string) => {
    if (value.length > 0) {
      setIsSelectLoading(true);
      getDiagnosticos(value).then((resp: any) => {
        const transformedResponse = resp?.data?.map((data: any) => ({
          value: data?.cie10,
          label: `[${data?.cie10}] ${data?.descripcion}`
        }));

        setOptions([...transformedResponse]);
      }).finally(() => setIsSelectLoading(false));
    }
  };

  const onSubmit = () => {
    let obj = {
      "nhc": parseInt(watch('nhc')),
      "eventNumber": watch('eventNumber'),
      "admissionDate": Moment(admissionDate).format('YYYY-MM-DD HH:mm:ss'),
      "attentionPlace": watch('attentionPlace'),
      "serviceDate": Moment(serviceDate).format('YYYY-MM-DD HH:mm:ss'),
      "address": {
        name: addressSelected?.name,
        reference: addressSelected?.reference,
        lat: addressSelected?.lat,
        lng: addressSelected?.lng,
        number: addressSelected?.number,
        "ubigeoId": watch('countryId') === 'PE' ? parseInt(watch('district')) : parseInt(watch('province'))
      },
      "plannedReleaseDate": Moment(plannedReleaseDate).format('YYYY-MM-DD HH:mm:ss'),
      "admissionOrigin": watch('admissionOrigin'),
      "admissionReason": (TEMP_COUNTRY === 'PE' ? watch('admissionReason') : ' '),
      "serviceType": (TEMP_COUNTRY === 'PE' ? watch('serviceType') : ' '),
      "providedService": watch('providedService'),
      "benefits": (TEMP_COUNTRY === 'PE' ? watch('benefits') : ' '),
      "medicalSpeciality": watch('medicalSpeciality'),
      "doctorId": null,
      "evaluatorDoctor": watch('evaluatorDoctor'),
      "admissionDiagnosis": admissionDiagnosis?.value
    };
    props.onSubmit(obj);
  }

  const getValeidate = () => {
    if (
      watch('nhc') &&
      watch('eventNumber') &&
      admissionDate &&
      watch('attentionPlace') &&
      serviceDate &&
      addressSelected?.name && addressSelected?.reference && addressSelected?.lat && addressSelected?.lng && addressSelected?.number &&
      plannedReleaseDate &&
      watch('admissionOrigin') &&
      watch('providedService') &&
      watch('medicalSpeciality') &&
      admissionDiagnosis &&
      watch('evaluatorDoctor') &&
      (TEMP_COUNTRY === 'PE' ? (watch('admissionReason') && watch('serviceType') && watch('benefits')) : true)
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (patientData) {
      let service = patientData?.service;
      setServiceData(service);
      setValue('nhc', patientData?.nhc)
      setAddressSelected({
        name: patientData?.service?.address ? patientData?.service?.address?.name : patientData?.address?.name,
        reference: patientData?.service?.address ? patientData?.service?.address?.reference : patientData?.address?.reference,
        lat: patientData?.service?.address ? patientData?.service?.address?.lat : patientData?.address?.lat,
        lng: patientData?.service?.address ? patientData?.service?.address?.lng : patientData?.address?.lng,
        number: patientData?.service?.address ? patientData?.service?.address?.number : patientData?.address?.number,
        ubigeoId: patientData?.service?.address ? parseInt(patientData?.service?.address?.ubigeoId) : patientData?.address?.ubigeoId
      })

      if (service) {
        setValue('eventNumber', service?.eventNumber || '')
        setValue('attentionPlace', service?.attentionPlace || '')
        setValue('reference', service?.address?.reference || '')
        setValue('departmentNumber', service?.address?.number || '')
        setValue('admissionOrigin', service?.admissionOrigin || '')
        setValue('admissionReason', service?.admissionReason || '')
        setValue('serviceType', service?.serviceType || '')
        setValue('providedService', service?.providedService || '')
        setValue('benefits', service?.benefits || '')
        setValue('serviceType', service?.serviceType || '')
        setValue('medicalSpeciality', service?.medicalSpeciality || '')
        setValue('evaluatorDoctor', service?.evaluatorDoctor || '')
        setValue('homeEnabled', service?.homeEnabled || 0)
        setdiAdmissionDiagnosis({
          value: service?.admissionDiagnosis || '',
          label: service?.admissionDiagnosisDescription ? `[${service?.admissionDiagnosis}] ${service?.admissionDiagnosisDescription}` : ''
        });
      }

      if (service?.admissionDate) {
        setAdmissionDate(new Date(service?.admissionDate));
      }
      if (service?.serviceDate) {
        setServiceDate(new Date(service?.serviceDate));
      }
      if (service?.plannedReleaseDate) {
        setPlannedReleaseDate(new Date(service?.plannedReleaseDate));
      }
      if (patientData?.address?.ubigeo) {
        const _countryId = patientData.address.ubigeo[0]?.countryId;
        setValue('countryId', _countryId);

        if (_countryId === 'PE') {
          setValue('district', patientData?.service?.address ? patientData?.service?.address?.ubigeo[2]?.id : patientData?.address?.ubigeo[2]?.id);
          onGetListLocations(_countryId, patientData?.service?.address ? patientData?.service?.address?.ubigeo[2]?.parentId : patientData?.address?.ubigeo[2]?.parentId, 'district');
        } else {
          setValue('province', patientData?.address?.ubigeo[1]?.id);

          onGetListLocations(_countryId, patientData?.address?.ubigeo[0]?.id, 'province');
        }
      }
    }

  }, [patientData])
  const onGetListLocations = async (countryId: any, parentId: any, type: string) => {
    const response = await getListLocations.mutateAsync({ countryId, parentId });
    if (response.data.length > 0) {
      switch (type) {
        case 'province':
          setListProvinces(response.data);
          break;
        case 'district':
          setListDistricts(response.data);
          break;
      }
    }
  }
  return (
    <section data-testid="FormTypeC">
      <div className="flex gap-4">
        <div className='flex flex-row justify-center items-center'>
          <span className='mr-1 ml-4'>
            <Icon iconName={IconUser} />
          </span>
          Datos de atención
        </div>
      </div>
      <div className="container px-1 py-5">
        <div className="w-full">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full ">

            <div className={`grid sm:grid-cols-3 gap-4`} >

              <div className="col">
                <Input
                  type="text"
                  typeValidation='number'
                  label={'Nº de historia clínica'}
                  max={99999}
                  maxLength={5}
                  min={1}
                  required
                  value={watch('nhc')}
                  {...register('nhc')}
                />
              </div>

              <div className="col">
                <InputDatePicker
                  label={t('date_and_time_of_admission')}
                  required
                  selected={admissionDate}
                  onChange={handleAdmissionDate}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  showTimeSelect
                  timeIntervals={15}
                  locale="es"
                  dateFormat="dd/MM/yyyy h:mm aa"
                />
              </div>

              <div className="col">
                <Input
                  type="text"
                  typeValidation='number'
                  required
                  maxLength={8}
                  minLength={8}
                  label={t('meeting_event_number')}
                  value={watch('eventNumber')}
                  {...register('eventNumber')}
                />
              </div>
              <div className="col">
                <InputDatePicker
                  label={t('service_start_date_and_time')}
                  selected={serviceDate}
                  onChange={handleServiceDate}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  showTimeSelect
                  timeIntervals={15}
                  locale="es"
                  dateFormat="dd/MM/yyyy h:mm aa"
                  minDate={admissionDate}
                />
              </div>
              {TEMP_COUNTRY === 'PE' ?
                (
                  <div className="col">
                    <Controller
                      control={control}
                      name='district'
                      render={({ field: { onChange, value, name, ref } }) => (
                        <InputSelect
                          label={t('district')}
                          name="district"
                          required
                          onSelect={val => {
                            onChange(val);
                          }}
                          value={value}
                          ref={ref}
                        >
                          {listDistricts?.length > 0 && listDistricts.map((item) => (
                            <option value={item.id} key={item.parentId + item.id}>
                              {item.name}
                            </option>
                          ))}
                        </InputSelect>
                      )}
                    />
                  </div>
                )
                :
                (
                  <div className="col">
                    <Controller
                      control={control}
                      name='province'
                      render={({ field: { onChange, value, name, ref } }) => (
                        <InputSelect
                          label="Municipio"
                          name="province"
                          required
                          onSelect={val => {
                            onChange(val);
                          }}
                          value={value}
                          ref={ref}
                        >
                          {listProvinces?.length > 0 && listProvinces.map((item) => (
                            <option value={item.id} key={item.parentId + item.id}>
                              {item.name}
                            </option>
                          ))}
                        </InputSelect>
                      )}
                    />
                  </div>
                )
              }
              <div className="col">
                <Input
                  label={t('address')}
                  required
                  icon={IconMap}
                  value={addressSelected?.name}
                  onClick={() => { setShowModal(true) }}
                />

                <ModalMap
                  showModal={showModal}
                  isEdit={patientData?.service?.id > 0}
                  data={{
                    name: patientData?.service?.address ? patientData?.service?.address?.name : patientData?.address?.name,
                    reference: patientData?.service?.address ? patientData?.service?.address?.reference : patientData?.address?.reference,
                    lat: patientData?.service?.address ? patientData?.service?.address?.lat : patientData?.address?.lat,
                    lng: patientData?.service?.address ? patientData?.service?.address?.lng : patientData?.address?.lng,
                    number: patientData?.service?.address ? patientData?.service?.address?.number : patientData?.address?.number,
                    ubigeoId: patientData?.service?.address ? parseInt(patientData?.service?.address?.ubigeoId) : patientData?.address?.ubigeoId
                  }}
                  region={patientData?.countryId === 'PE' ? 'pe' : 'co'}
                  onCloseModal={() => { setShowModal(false) }}
                  onSubmitAddress={(data: AddresSelectedModel) => {
                    setAddressSelected(data);
                    setShowModal(false);
                  }}
                />
              </div>

              <div className="col">
                <InputDatePicker
                  label={t('discharge_date')}
                  selected={plannedReleaseDate}
                  onChange={handleDischargeDate}
                  locale="es"
                  dateFormat="dd/MM/yyyy"
                  minDate={serviceDate}
                />
              </div>
              <div className="col">
                <div
                  className=' w-full max-w-full h-[56px] text-body1 relative   flex items-center justify-start '  >
                  <div className=' px-[16px] !w-full h-[56px] rounded-[16px] bg-grey-50 text-grey-600 pt-4 outline-none text-body1 '>
                    <label
                      className={` top-2	left-0 px-[16px] absolute text-gray-600 text-caption  `}
                    >
                      {t('days_of_hospitalization')}
                    </label>
                    <div className='mt-2'>
                      {(Moment(Moment(plannedReleaseDate).format('YYYY-MM-DD')).diff((Moment(serviceDate).format('YYYY-MM-DD')), 'days')) + 1}

                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <Controller
                  control={control}
                  name='admissionOrigin'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label={t('income_source')}
                      name="admissionOrigin"
                      required
                      onSelect={val => {
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                    >
                      {
                        sourceAdmission?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                      }
                    </InputSelect>
                  )}
                />
              </div>

              {TEMP_COUNTRY === 'PE' &&
                <React.Fragment>
                  <div className="col">
                    <Controller
                      control={control}
                      name='admissionReason'
                      render={({ field: { onChange, value, name, ref } }) => (
                        <InputSelect
                          label={t('reason_for_admission')}
                          name="admissionReason"
                          required
                          onSelect={val => {
                            onChange(val);
                          }}
                          value={value}
                          ref={ref}
                        >
                          {peruOptionsReasonForAdmission?.map((perOpt) => (
                            <option value={perOpt.value} key={perOpt.value}>
                              {t(`${perOpt.label}`)}
                            </option>
                          ))}
                        </InputSelect>
                      )}
                    />
                  </div>
                  <div className="col">
                    <Controller
                      control={control}
                      name='serviceType'
                      render={({ field: { onChange, value, name, ref } }) => (
                        <InputSelect
                          label={t('type')}
                          name="serviceType"
                          required
                          onSelect={val => {
                            onChange(val);
                          }}
                          value={value}
                          ref={ref}
                        >
                          {peruOptionsReasonForAdmission?.map((perOpt) => (
                            <option value={perOpt.value} key={perOpt.value}>
                              {t(`${perOpt.label}`)}
                            </option>
                          ))}
                        </InputSelect>
                      )}
                    />
                  </div>
                </React.Fragment>
              }

              <div className="col">
                <Controller
                  control={control}
                  name='attentionPlace'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label={t('place_of_care')}
                      name="attentionPlace"
                      required
                      onSelect={val => {
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                    >
                      {
                        placeAttention?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                      }
                    </InputSelect>
                  )}
                />
              </div>
              <div className="col">
                <Controller
                  control={control}
                  name='providedService'
                  render={({ field: { onChange, value, name, ref } }) => (
                    <InputSelect
                      label={t('service')}
                      name="providedService"
                      required
                      onSelect={val => {
                        onChange(val);
                      }}
                      value={value}
                      ref={ref}
                    >
                      {
                        serviceProvided?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                      }
                    </InputSelect>
                  )}
                />
              </div>

              {TEMP_COUNTRY === 'PE' &&
                <div className="col">
                  <Controller
                    control={control}
                    name='benefits'
                    render={({ field: { onChange, value, name, ref } }) => (
                      <InputSelect
                        label={t('benefits')}
                        name="benefits"
                        required
                        onSelect={val => {
                          onChange(val);
                        }}
                        value={value}
                        ref={ref}
                      >
                        {
                          benefits?.map((colOpt: { value: string; label: string }) => (<option key={colOpt.value} value={colOpt.value}> {t(`${colOpt.label}`)} </option>))
                        }
                      </InputSelect>
                    )}
                  />
                </div>

              }

              <div className="col ">
                <Input
                  type="text"
                  typeValidation='text'
                  required
                  label={"Servicio actual"}
                  value={watch('medicalSpeciality')}
                  {...register('medicalSpeciality')}
                />
              </div>

              <div className="col ">
                <InputSelectSearch
                  label='Diagnóstico de la atención'
                  value={admissionDiagnosis}
                  options={options}
                  required
                  onSearch={handleInputSelectChange}
                  onSelect={handleSelectChangeInfo}
                  isLoading={isSelectLoading}
                />
              </div>
              <div className="col">
                <Input
                  label="Médico evaluador"
                  type="text"
                  typeValidation='text'
                  required
                  maxLength={100}
                  value={watch('evaluatorDoctor')}
                  {...register('evaluatorDoctor')}
                />
              </div>

              {
                patientData?.service?.eventNumber && (
                  <div className="col">
                    <div
                      onClick={() => setShowHomeForm(true)}
                      className='cursor-pointer w-full max-w-full h-[60px] text-body1 relative   flex items-center justify-start '  >
                      <div className=' px-[16px] !w-full h-[60px] rounded-[16px] bg-grey-50 text-grey-600 pt-4 outline-none text-body1 '>
                        <label
                          className={` top-1	left-0 px-[16px] absolute text-gray-600 text-caption  `}
                        >
                          {t('enabled_home')}
                        </label>
                        <div className='w-[110px] relative top-[8px]'>
                          <Chip variant='secondary' className=' justify-center ' label={serviceData?.homeEnabled === 0 ? "No" : "Si"} onClose={() => { }} />
                        </div>
                        <img src={ArrowRightIcon} className="w-[24px] h-[24px] absolute right-[8px] top-[17px] pointer-events-none" />
                      </div>
                    </div>
                  </div>
                )
              }

            </div>
            <div className="flex justify-center gap-4 mt-6">
              <Button type="button" variant='secondary' size="extra-large" onClick={() => props.setStep(0)} >Cancelar</Button>
              <Button type="submit" variant='primary' size="extra-large" disabled={!getValeidate()} >Guardar</Button>
            </div>
          </form>

          {
            showHomeForm &&
            <HomeConditionForm
              serviceId={props.allDatapatient?.service?.id}
              onCloseModal={() => { setShowHomeForm(false) }}
              onReloadPatient={() => {
                props.onReloadPatient();
              }}
            />
          }
        </div>
      </div>
    </section>
  )
}

export default FormService

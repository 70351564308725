import { useState, useEffect } from 'react'
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
  useParams,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TEMP_COUNTRY_DEFAULT } from 'global/constants'
import { routes } from 'routes/routing'

import {
  PatientModel,
  PatientResponsibleModel,
} from 'features/Patient/models/Patient.model'
import usePostRegisterResponsible from 'features/shared/hooks/usePostRegisterResponsible'
import usePostRegisterService from 'features/shared/hooks/usePostRegisterService'
import usePostRegisterEconomic from 'features/shared/hooks/usePostRegisterEconomic'
import usePutRegisterEconomic from 'features/shared/hooks/usePutRegisterEconomic'
import usePutRegisterService from 'features/shared/hooks/usePutRegisterService'
import usePutRegisterResponsible from 'features/shared/hooks/usePutRegisterResponsible'
import { useGetPatientData } from 'features/shared/hooks/useGetPatientFullData'
import { useUpdatePatient } from 'features/shared/hooks/usePostRegisterPatient'
import {
  NotificationCard,
} from 'features/Notifications/components/NotificationCard/NotificationCardNew'
import useUser from 'features/shared/hooks/useUser'
import useGetSelectOptions from 'features/shared/hooks/useGetSelectOptions'

import Button from 'components/Button'
import FormPatient from 'components/FormPatient'
import ButtonToggle from 'components/ButtonToggle'
import Loader from 'components/Loader'
import FormResponsibleOrCompanion from 'components/FormResponsibleOrCompanion'
import FormService from 'components/FormService'
import FormCheap from 'components/FormCheap'
import { ShadowWrapper } from 'components/ShadowWrapper'
import Breadcrumbs from 'components/Breadcrumbs'

import Profile from './Profile'

import IconChevronDown from 'assets/icons/icon_chevron_down.svg'
import IconPlusCircle from 'assets/icons/icon_plus_circle.svg'
import IconFile from 'assets/icons/icon_file.svg'
import IconInfo from 'assets/icons/icon_info.svg'
import { onAlert } from 'components/Alert'
import { NotificationType } from 'features/Notifications/services'
import { ReadNotificationCard } from 'features/Notifications/components/NotificationCard/NotificationCard'

interface RouteParams {
  patientId: string
}

const MonitoringPatient: React.FC<any> = (props) => {
  const history = useHistory()
  const location = useLocation()
  let { path, url } = useRouteMatch()
  const params = useParams<RouteParams>()
  const [allDataPatient, setAllDataPatient] = useState<PatientModel>()
  const [sectionStepFormPatient, setSectionStepFormPatient] =
    useState<number>(2)
  const { mutateAsync: asynUpdatePatient, isLoading: loadingUpdatePatient } =
    useUpdatePatient()
  const { mutateAsync: asynGetPatientData, isLoading: loadingPatientData } =
    useGetPatientData()
  const postRegisterResponsible = usePostRegisterResponsible()
  const postRegisterService = usePostRegisterService()
  const postRegisterEconomic = usePostRegisterEconomic()
  const putRegisterEconomic = usePutRegisterEconomic()
  const putRegisterResponsible = usePutRegisterResponsible()
  const putRegisterService = usePutRegisterService()
  const { userCurrent } = useUser()
  let TEMP_COUNTRY = userCurrent?.ipress?.country_id
    ? userCurrent?.ipress?.country_id
    : TEMP_COUNTRY_DEFAULT
  const getSelectOptions = useGetSelectOptions(TEMP_COUNTRY)
  const selectOptions = getSelectOptions?.data?.data
  const { t } = useTranslation()
  const [serviceid, setServiceid] = useState()
  const [textSuccess, setTextSuccess] = useState('Registro exitoso')

  const [newCompanionPatient, setNewCompanionPatient] = useState<boolean>(false)
  const [companionPatientActive, setCompanionPatientActive] =
    useState<number>(0)

  const [step, setStep] = useState(1)
  const [aside, setAside] = useState(true)
  const isAside: () => boolean = () =>
    aside && sectionStepFormPatient === 2 && step === 0

  useEffect(() => {
    onGetPatient()
  }, [])

  const updateDataPatient = async (data: PatientModel) => {
    const responseUpdatePatient = await asynUpdatePatient({
      data,
      id: params.patientId,
    })
    if (Number.isInteger(responseUpdatePatient?.data)) {
      onAlert.success(textSuccess)
      const userData = { ...allDataPatient, ...data }
      setAllDataPatient(userData)
    } else {

      if (responseUpdatePatient?.data) {
        onAlert.error(responseUpdatePatient?.data)
      } else {
        onAlert.error(responseUpdatePatient)
      }
    }
  }

  const onGetPatient = async () => {
    const dataPatient = await asynGetPatientData(params.patientId)

    if (dataPatient?.data) setAllDataPatient(dataPatient.data)

    if (dataPatient?.data?.id && dataPatient?.responsibles?.length === 0)
      setNewCompanionPatient(true)
  }

  const onSubmitResponsible = async (
    data: any,
    isEdit: boolean,
    responsibleId: number
  ) => {
    let responsibletDataSend
    let responsibles: PatientResponsibleModel[] = allDataPatient?.responsibles
      ? allDataPatient?.responsibles
      : []

    if (isEdit) {
      setTextSuccess('Responsable actualizado')
      let objPut = { patientId: allDataPatient?.userId, ...data }

      responsibletDataSend = await putRegisterResponsible.mutateAsync({
        patientId: allDataPatient?.userId,
        responsibleId,
        data: objPut,
      })

      responsibles = responsibles.map((e: PatientResponsibleModel) =>
        e.id === responsibleId ? { ...e, ...data } : e
      )
    } else {
      setTextSuccess('Registro exitoso')
      let objPost = { patientId: allDataPatient?.userId, ...data }

      responsibletDataSend = await postRegisterResponsible.mutateAsync(objPost)
      responsibles?.push({ ...data, id: responsibletDataSend?.data })
    }

    if (responsibles && allDataPatient)
      setAllDataPatient({ ...allDataPatient, responsibles })

    if (Number.isInteger(responsibletDataSend?.data)) {
      onAlert.success(textSuccess)
    } else {


      if (responsibletDataSend?.data) {
        onAlert.error(responsibletDataSend?.data)
      } else {
        onAlert.error(responsibletDataSend)
      }
    }

    setNewCompanionPatient(false)
  }

  const onSubmitServicio = async (data: any) => {
    setTextSuccess('Servicio actualizado')
    let registerService

    if (allDataPatient?.service) {
      registerService = await putRegisterService.mutateAsync({
        patientId: allDataPatient?.userId,
        serviceId: allDataPatient?.service?.id,
        data: { ...data },
      })
    } else {
      registerService = await postRegisterService.mutateAsync({
        patientId: allDataPatient?.userId,
        ...data,
      })
      onGetPatient()
    }

    if (registerService?.data) {
      onAlert.success(textSuccess)
      setServiceid(registerService?.data)
    } else {

      if (registerService?.data) {
        onAlert.error(registerService?.data)
      } else {
        onAlert.error(registerService)
      }
    }
  }

  const onSubmitEconomicos = async (data: any) => {
    setTextSuccess('Datos económicos actualizado')

    if (allDataPatient?.service) {
      let objPost = {
        serviceId: serviceid ? serviceid : allDataPatient?.service?.id,
        ...data,
      }
      let objPut = {
        ...data,
      }

      let patientDataSend

      if (allDataPatient?.service?.economic) {
        patientDataSend = await putRegisterEconomic.mutateAsync({
          patientId: allDataPatient?.userId,
          serviceId: allDataPatient?.service?.id,
          data: objPut,
        })
      } else {
        patientDataSend = await postRegisterEconomic.mutateAsync(objPost)
      }

      onGetPatient()

      if (Number.isInteger(patientDataSend?.data)) {
        onAlert.success(textSuccess)
      } else {

        if (patientDataSend?.data) {
          onAlert.error(patientDataSend?.data)
        } else {
          onAlert.error(patientDataSend)
        }
      }
    }
  }

  useEffect(() => {
    switch (location.pathname) {
      case `${url}/responsible`:
        setStep(1)
        break
      case `${url}/service`:
        setStep(2)
        break
      case `${url}/economic`:
        setStep(3)
        break
      default:
        setStep(0)
        break
    }
  }, [location])

  const isLoading = loadingPatientData ||
    loadingUpdatePatient ||
    postRegisterResponsible.isLoading ||
    postRegisterService.isLoading ||
    postRegisterEconomic.isLoading ||
    putRegisterEconomic.isLoading ||
    putRegisterResponsible.isLoading ||
    putRegisterService.isLoading ||
    getSelectOptions.isLoading;

  return (
    <>
      {(isLoading) && <Loader />}

      <Breadcrumbs
        name={`${allDataPatient?.name} ${allDataPatient?.lastname}`}
        prevPathName="Monitoreo"
        currentPathName={`${allDataPatient?.name} ${allDataPatient?.lastname}`}
        onClickPrev={() => {
          history.push(`${routes.monitoring.url}`)
        }}
      />

      <div className="container px-1 py-5 mt-4">
        <div
          data-testid="Add"
          className="w-full flex flex-col  min-h-screen  justify-start"
        >
          <div className="flex justify-start gap-2 mb-8 overflow-x-auto overflow-y-none">
            <Button
              type="button"
              size="small"
              className="whitespace-nowrap"
              variant={step === 0 ? 'primary' : 'secondary'}
              onClick={() => {
                history.push(`${url}`)
              }}
            >
              Datos del paciente
            </Button>
            <Button
              type="button"
              size="small"
              className="whitespace-nowrap"
              variant={step === 1 ? 'primary' : 'secondary'}
              onClick={() => {
                history.push(`${url}/responsible`)
              }}
            >
              Datos del responsable
            </Button>
            <Button
              type="button"
              size="small"
              className="whitespace-nowrap"
              variant={step === 2 ? 'primary' : 'secondary'}
              onClick={() => {
                history.push(`${url}/service`)
              }}
            >
              Datos del servicio
            </Button>
            <Button
              type="button"
              size="small"
              className="whitespace-nowrap"
              variant={step === 3 ? 'primary' : 'secondary'}
              onClick={() => {
                history.push(`${url}/economic`)
              }}
            >
              Datos económicos
            </Button>
          </div>
          <div className={`${isAside() && 'flex flex-col xl:flex-row	 gap-2'}`}>
            <div
              className={` w-full bg-white py-6 px-4 rounded-[16px] shadow-md
            ${isAside() && 'shrink grow xl:max-w-[65%]'}
            `}
            >
              <Switch>
                <Route exact path={path}>
                  <div className="flex flex-wrap gap-4 ">
                    <ButtonToggle
                      icon={IconInfo}
                      isActive={sectionStepFormPatient === 2}
                      onClick={() => {
                        setSectionStepFormPatient(2)
                        setAside(true)
                      }}
                    >
                      Perfil
                    </ButtonToggle>
                    <ButtonToggle
                      icon={IconInfo}
                      isActive={sectionStepFormPatient === 0}
                      onClick={() => {
                        setSectionStepFormPatient(0)
                        setAside(false)
                      }}
                    >
                      Información Personal
                    </ButtonToggle>

                    <ButtonToggle
                      icon={IconFile}
                      isActive={sectionStepFormPatient === 1}
                      onClick={() => {
                        setSectionStepFormPatient(1)
                        setAside(false)
                      }}
                    >
                      Información de Contacto
                    </ButtonToggle>
                  </div>

                  {allDataPatient && sectionStepFormPatient !== 2 && (
                    <FormPatient
                      setSectionStepFormPatient={setSectionStepFormPatient}
                      formStep={sectionStepFormPatient}
                      isEdit={true}
                      selectOptions={selectOptions}
                      dataPatient={allDataPatient}
                      onSubmit={updateDataPatient}
                    />
                  )}
                  {sectionStepFormPatient === 2 && (
                    <Profile patient={allDataPatient} />
                  )}
                </Route>

                <Route exact path={`${path}/responsible`}>
                  <div className="flex flex-wrap gap-2 justify-between items-center">
                    <span className="text-subtitle1">Acompañantes</span>
                    <ButtonToggle
                      icon={IconPlusCircle}
                      isActive={!newCompanionPatient}
                      disabled={newCompanionPatient}
                      onClick={() => setNewCompanionPatient(true)}
                    >
                      Añadir acompañante
                    </ButtonToggle>
                  </div>

                  {newCompanionPatient ? (
                    <FormResponsibleOrCompanion
                      dataPatient={allDataPatient}
                      isEdit={false}
                      data={null}
                      selectOptions={selectOptions}
                      onSubmit={(data: any) =>
                        onSubmitResponsible(data, false, 0)
                      }
                      onCancel={() => setNewCompanionPatient(false)}
                    />
                  ) : (
                    <>
                      {allDataPatient &&
                        allDataPatient?.responsibles?.map(
                          (item: PatientResponsibleModel, index: number) => (
                            <div
                              className="border-solid border-[1px] border-grey-200 p-4 rounded-lg mt-6"
                              key={item.id}
                            >
                              <div
                                className={`
                              border-b-solid border-b-[1px] border-b-grey-200 flex items-center justify-between cursor-pointer pb-4
                              ${companionPatientActive === index
                                    ? 'pointer-events-none'
                                    : ''
                                  }
                              ${companionPatientActive === index
                                    ? ''
                                    : 'border-none pb-[0px]'
                                  }
                            `}
                                onClick={() => {
                                  setCompanionPatientActive(index)
                                }}
                              >
                                <span className="text-body1">
                                  {item.name} {item.lastname}
                                </span>{' '}
                                <img src={IconChevronDown} alt="more" />
                              </div>

                              {companionPatientActive === index && (
                                <FormResponsibleOrCompanion
                                  dataPatient={allDataPatient}
                                  isEdit={true}
                                  data={item}
                                  selectOptions={selectOptions}
                                  onSubmit={(data: any) =>
                                    onSubmitResponsible(data, true, item?.id)
                                  }
                                  onCancel={() => { }}
                                />
                              )}
                            </div>
                          )
                        )}
                    </>
                  )}
                </Route>
                <Route exact path={`${path}/service`}>
                  {allDataPatient && (
                    <FormService
                      allDatapatient={allDataPatient}
                      // medicoEvaluador={[{ id: 1, name: 'Alejandra', lastname: 'Terrones' }]}
                      onReloadPatient={() => {
                        onGetPatient()
                      }}
                      medicoCampo={[]}
                      setStep={() => { }}
                      onSubmit={onSubmitServicio}
                      pacienteId={allDataPatient?.userId}
                      selectOptions={selectOptions}
                    />
                  )}
                </Route>
                <Route exact path={`${path}/economic`}>
                  <FormCheap
                    selectOptions={selectOptions}
                    allDatapatient={allDataPatient}
                    setStep={() => { }}
                    onSubmit={onSubmitEconomicos}
                  />
                </Route>
              </Switch>
            </div>
            {isAside() && (
              <aside className="mt-2 xl:w-[315px] xl:ml-4 xl:mt-0">
                <ShadowWrapper className="px-4 mb-6">
                  <h2 className="font-semibold mb-6">Alertas recientes</h2>
                  <NotificationCard
                    alert={true}
                    notification={{
                      data: {
                        title: 'Titulo',
                        //TODO: Fill up with real notifications.
                        body: {
                          eventType: '',
                          userIdEmitter: 1,
                          patientId: parseInt(params.patientId || ""),
                          planId: allDataPatient?.service?.id,
                        },
                      },
                      notificationType: NotificationType.call,
                      readAt: null,
                      createdAt: '2020-10-07T19:24:29.058Z',
                      name: 'nombre',
                      notificationId: 1,
                    }}
                  />
                </ShadowWrapper>
                <ShadowWrapper className="px-4">
                  <h2 className="font-semibold mb-6">Alertas anteriores</h2>
                  <ReadNotificationCard
                    alert={true}
                    notification={{
                      data: {
                        title: 'Titulo',
                        body: {
                          eventType: '',
                          userIdEmitter: 1,
                          patientId: 3,
                        },
                      },
                      notificationType: NotificationType.call,
                      createdAt: '2020-10-07T19:24:29.058Z',
                      readAt: '2020-10-07T19:24:29.058Z',
                      name: 'nombre',
                      notificationId: 1,
                      attendedName: 'attendedname',
                    }}
                  />
                </ShadowWrapper>
              </aside>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MonitoringPatient


import { MeetingVideoCall, MeetingVideoCallProps } from "components/MeetingVideoCall";
import { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import IconClose from 'assets/icons/icon_close_white.svg'
import IconPhoneHang from 'assets/icons/icon_phone_white_down.svg'
import IconPhonePick from 'assets/icons/icon_phone_white.svg'
import useUser from "features/shared/hooks/useUser";
import { toast } from "react-toastify";
import "./currentCallContext.css"

export interface ICallContext {
  currentMeeting?: MeetingVideoCallProps,
  setCurrentMeeting: Dispatch<SetStateAction<MeetingVideoCallProps | undefined>>
}

export const CallContext = createContext<ICallContext>({
  setCurrentMeeting: () => { }
})

export const CallContextProvider = ({
  children
}: {
  children: any
}) => {
  const [currentMeeting, setCurrentMeeting] = useState<MeetingVideoCallProps | undefined>();
  const { userCurrent } = useUser();

  const value = {
    currentMeeting,
    setCurrentMeeting
  };

  useEffect(() => {
    //Initialize a call upon checking the website.
    const urlParams = new URLSearchParams(window.location.search);
    const isMeeting = urlParams.get('meeting') === 'true';
    const meetingPlanId = parseInt(urlParams.get('meetingPlanId') || "");
    const userId = userCurrent?.id;
    if (isMeeting && !isNaN(meetingPlanId) && !isNaN(userId) && !currentMeeting) {
      setCurrentMeeting({
        planId: meetingPlanId,
        userId: userId,
        getIsClose: () => {
          setCurrentMeeting(undefined)
        },
        initCall: true
      })
    }
  }, [userCurrent?.id, window.location])

  const hideModal = (incomingCallModalId: string) => {
    toast.dismiss(incomingCallModalId || "");
  }

  const answerCall = (callData: any) => {
    setCurrentMeeting({
      planId: callData.planId,
      userId: callData.userId,
      initCall: true,
      getIsClose: () => {
        setCurrentMeeting(undefined)
      }
    })
  }


  const handleEventReceived = ({
    data: {
      type,
      payload
    }
  }: {
    data: {
      type: string,
      payload: any
    }
  }) => {
    if (type === 'notificationReceived') {
      if (payload.subAction === 'notificationClicked') {
        const dismissModalId = (window as any)['modalId'];
        if (dismissModalId) {
          hideModal(dismissModalId);
        }
        return;
      }
      const body = payload?.options?.body || {};
      const type = body.type || body.notificationType || "";
      const isReceivingEnd = userCurrent?.id == body.userIdReceiver;
      if (type.endsWith('call') && !currentMeeting && isReceivingEnd) {
        let newIncomingCallData = {
          planId: body.planId,
          userId: userCurrent?.id
        }
        const callData = newIncomingCallData;
        const modalId = toast(<div>
          <span className="font-poppins text-base font-medium leading-6 tracking-wide text-left text-[#141414]">Llamada entrante</span>
          <div className="w-full flex items-center justify-center mt-[24px]">
            <div className="w-[132px] h-[40px] p-[10px_22px] gap-[4px] rounded-[12px] bg-[#FB3836] cursor-pointer flex justify-center inline" onClick={() => {
              hideModal(modalId.toString());
            }}>
              <img src={IconPhoneHang} alt="close" className='w-6 h-6' />
              <span className="text-white font-poppins text-sm font-normal leading-4 tracking-tight text-left flex justify-center items-center">
                Rechazar
              </span>
            </div>
            <div className="w-[132px] h-[40px] p-[10px_22px] gap-[4px] rounded-[12px] bg-[#48B16E] cursor-pointer ml-[12px] flex justify-center inline" onClick={() => {
              hideModal(modalId.toString());
              answerCall(callData);
            }}>
              <img src={IconPhonePick} alt="close" className='w-5 h-5' />
              <span className="text-white font-poppins text-sm font-normal leading-4 tracking-tight text-left flex justify-center items-center">
                Aceptar
              </span>
            </div>
          </div>
        </div>, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          containerId: "call-notification",
          style: {
            width: "320px",
          },
          closeButton: false,
        });
        //We use window due the fact the toast id is not available in the state
        (window as any)['modalId'] = modalId.toString();
      }
    }
  }


  useEffect(() => {
    const handler = (event: any) => {
      handleEventReceived(event);
    }
    if ('serviceWorker' in navigator) {
      console.log("Adding listener...");
      navigator.serviceWorker.addEventListener('message', handler);
    }

    return () => {
      console.log("Removing listener...");
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.removeEventListener('message', handler);
      }
    }
  }, [navigator?.serviceWorker?.ready, userCurrent?.id]);

  return (
    <CallContext.Provider value={value}>
      {
        currentMeeting ?
          <div className="fixed w-screen h-screen z-[10000] left-0 top-0 bg-black/40 flex justify-center items-center">
            <span className="absolute right-0 top-0 p-8 cursor-pointer" onClick={() => {
              setCurrentMeeting(undefined)
            }}>
              <img src={IconClose} alt={IconClose} style={{
                width: "2em",
                height: "3em"
              }} />
            </span>
            <MeetingVideoCall
              planId={currentMeeting.planId}
              userId={currentMeeting.userId}
              userProfile={currentMeeting.userProfile}
              getIsClose={currentMeeting.getIsClose}
              initCall={currentMeeting.initCall}
            />
          </div> : null
      }
      {children}
    </CallContext.Provider>
  )
}
import { Redirect, useParams } from 'react-router-dom'
import { routes } from 'routes/routing'

const Wrapper: React.FC = ({ children }) => <>{children}</>

export const getBaseReplacedRoute = ({
  route,
  params
}: {
  route: string,
  params: {
    [key: string]: string | number
  }
}) => {
  Object.keys(params || {}).forEach(element => {
    route = route.replace(`/${params[element]}`, `/:${element}`)
  })
  return route;
}

export const getBaseRoute = ({
  route,
  params
}: {
  route: string,
  params: {
    [key: string]: string | number
  }
}) => {
  route = getBaseReplacedRoute({
    route,
    params
  })
  return (routes as any)[Object.keys(routes as any).find((element: any) => {
    return (routes as any)[element].url === route
  }) || "" as any]
}


export const CheckShowPermissionsRouter = ({
  children,
  roleSlug,
  locationPathName,
}: {
  children: any,
  roleSlug: string,
  locationPathName: string,
}) => {
  const params = useParams();
  const route = getBaseRoute({
    route: locationPathName,
    params
  });
  if (roleSlug && route && Array.isArray(route.allowedRolesAccess) && !route.allowedRolesAccess.includes(roleSlug)) {
    console.error("No tiene permiso para acceder a esta ruta");
    console.log({
      roleSlug,
      route
    });
    const urlParams = new URLSearchParams(window.location.search);
    return <Redirect push={true} to={`/${urlParams.size > 0 ? `?${urlParams.toString()}` : ''}`} />;
  }
  return <>
    {children}
  </>
}
import { useState, useEffect } from 'react'
import {
  LocalVideo,
  useLocalVideo,
  useRemoteVideoTileState,
  RemoteVideo,
  useFeaturedTileState,
  useSelectVideoQuality,
  useRosterState
} from "amazon-chime-sdk-component-library-react"
import IconVideo from 'assets/icons/icon_video_control_video.svg'
import IconVideoBasic from 'assets/icons/icon_video_local.svg'
import IconMic from 'assets/icons/icon_video_control_mic.svg'
import IconPhone from 'assets/icons/icon_phone_white_down.svg'
import IconVideoOff from 'assets/icons/icon_video_local_off.svg'
import IconMute from 'assets/icons/icon_mute.svg'

interface Props {
  videoTiles?: boolean
  handleAudio?: any
  mute?: boolean
  attendeeId?: string
  handleLeaveMeeting?: React.MouseEventHandler<HTMLButtonElement>
  alias?: string
}

const Chime: React.FC<Props> = ({
  videoTiles,
  handleAudio,
  mute,
  handleLeaveMeeting,
  alias
}) => {
  const { toggleVideo } = useLocalVideo()
  const { tileId } = useFeaturedTileState()
  const { tiles } = useRemoteVideoTileState()
  const selectVideoQuality = useSelectVideoQuality()
  const [isVideo, setIsVideo] = useState(false)
  const { roster } = useRosterState()

  const localVid = document.getElementById("localvideo")
  const remoteVid = document.getElementById(`remote_${tileId}`)
  if (localVid) {
    if (tileId === null) {
      localVid.classList.add("localvideo_main")
      localVid.classList.remove("localvideo_side")
    } else {
      localVid.classList.add("localvideo_side")
      localVid.classList.remove("localvideo_main")
    }
  }
  if (remoteVid) {
    if (tileId === null) {
      remoteVid.classList.add("remotevideo")
      remoteVid.classList.remove("ch-featured-tile")
    } else {
      remoteVid.classList.remove("remotevideo")
      remoteVid.classList.add("ch-featured-tile")
    }
  }

  const handleClickButtonVideo = () => {
    toggleVideo()
    setIsVideo(!isVideo)
    selectVideoQuality("360p")
  }

  const getAliasByUserJoined = (roster: any) => {
    const aux = Object.keys(roster)
    let config: any = roster[aux[1]]
    return {
      fullName: `${config?.name || ''} ${config?.lastName || ''}`,
      abbr: `${config?.name?.charAt(0) || ''}${config?.lastName?.charAt(0) || ''}`
    }
  }

  if (!videoTiles) {
    return <></>
  }

  return (
    <div
      className="
      rounded-[16px]
      bg-white
      w-[80vw]
      xl:w-[1026px]
      h-[593px]
      xl:h-[636px]
      border border-solid border-[#F1F1F1]
      relative
      pl-6
      pr-6
      pb-10
    ">
      <div className='w-full h-[56px] flex inline items-center'>
        <img src={IconVideoBasic} className='w-8 h-8' />
        <span className='ml-2'>
          Videollamada
        </span>
      </div>
      <div className='bg-[#252525] w-full h-[90%] relative  rounded-[12px] text-white flex items-center justify-center'>
        <div className='h-full w-full flex flex-col items-center justify-center relative'>
          <div className='h-full w-full flex items-center justify-center w-[102%] h-[102%]'>
            {
              tiles?.length == 0 ?
                <div className='avatar-element flex flex-col items-center justify-center'>
                  <div className='bg-[#DFF5FF] w-[120px] h-[120px] rounded-full text-[#008EA3] uppercase flex items-center justify-center'>
                    <span className='font-poppins text-3xl font-medium leading-6 tracking-wide text-center'>
                      {getAliasByUserJoined(roster)?.abbr}
                    </span>
                  </div>
                  <div className='flex-col items-center justify-center pt-[12px]'>
                    {
                      Object.keys(roster).length > 1 ? <div className='font-poppins text-base font-semibold leading-6 tracking-wide text-center'>
                        {getAliasByUserJoined(roster)?.fullName}
                      </div> : null
                    }
                    {
                      Object.keys(roster).length === 1 ? <div className='font-poppins text-sm font-medium leading-6 tracking-wide text-center'>
                        Llamando...
                      </div> : null
                    }
                  </div>
                </div> : null
            }
            {
              tiles?.length > 0 ?
                tiles.map((item) =>
                  <RemoteVideo
                    tileId={item}
                    id={`remote_${item}`}
                    className="remotevideo w-full h-full rounded-[20px] overflow-hidden"
                  />)
                : null
            }
            &nbsp;
          </div>
          {
            isVideo &&
            <div className='w-[122px] h-[98px] absolute  rounded-lg overflow-hidden bg-[#1A4F73] right-2 bottom-2'>
              <div className="flex items-center justify-center h-[100%] absolute w-[100%]">
                <div className="flex items-center justify-center bg-[#DFF5FF] text-3xl w-[66px] h-[65px] rounded-full text-[#008EA3] uppercase">{alias}</div>
              </div>
              <LocalVideo id="localvideo" className='absolute top-0' />
            </div>
          }
          <div className='w-full absolute h-[60px] bottom-0 flex justify-center items-center'>
            <div className='flex inline'>
              <img src={mute ? IconMic : IconMute} onClick={handleAudio} className='w-8 h-8 ml-2 cursor-pointer' />
              <img src={isVideo ? IconVideo : IconVideoOff} onClick={handleClickButtonVideo} className='w-8 h-8 ml-2 cursor-pointer' />
              <span className='w-[58px] p-[4px_20px] gap-[10px] rounded-[8px] bg-[#FB3836] flex items-center justify-center h-8 ml-2 cursor-pointer'
                onClick={handleLeaveMeeting}>
                <img src={IconPhone} className='min-w-[20px]' />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chime
